import { useEffect, useState } from "react";
import { Container } from "@/components/grid/container";
import { Row } from "@/components/grid/row";
import { Column } from "@/components/grid/column";
import { fetchApiData, CountdownApiData } from "@/util/fetchCountdownApiData";

import "./countdown.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlipClock from "@millmen/react-flip-clock";

const Countdown = () => {
  const [countdownApiData, setCountdownApiData] = useState<CountdownApiData>();

  const settings = {
    className: "robin-n-julia__slider",
    dots: false,
    fade: true,
    infinite: true,
    centerMode: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  useEffect(() => {
    fetchApiData()
      .then((data) => setCountdownApiData(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container extraClassName="h-md-100">
      <Row extraClassName="h-md-100">
        <Column xs={12} md={6} extraClassName="robin-n-julia">
          <h1 className="robin-n-julia__title">Robin + Julia 💕</h1>
          <Slider {...settings}>
            {countdownApiData?.images.map((image, index) => (
              <div key={index} className="robin-n-julia__image-container">
                <div
                  className="robin-n-julia__image"
                  style={{
                    backgroundImage: `url(${image.url})`,
                    backgroundPositionX: image.imagePosition.x,
                    backgroundPositionY: image.imagePosition.y
                  }}
                />
              </div>
            ))}
          </Slider>
        </Column>
        <Column xs={12} md={6} extraClassName="counter">
          <h2 className="counter__title">
            Counting down until we are together again 💖
          </h2>
          <FlipClock
            countDownDate={
              countdownApiData?.date
                ? new Date(countdownApiData.date)
                : new Date()
            }
          />
        </Column>
      </Row>
    </Container>
  );
};

export default Countdown;
