interface CountdownApiResponseImage {
    path: string;
    imagePosition: { x: string; y: string };
}

export interface CountdownApiData {
    date: string;
    images: { url: string; imagePosition: { x: string; y: string } }[];
}

export const fetchApiData = () => new Promise<CountdownApiData>((resolve, reject) => {
    fetch(process.env.REACT_APP_API_FUNCTIONS_BASE_URL as string)
      .then((response) => response.json())
      .then((response) => {
        resolve({
            ...response,
            images: response.images.map((image: CountdownApiResponseImage) => ({ ...image, url: `${process.env.REACT_APP_API_BASE_URL}${image.path}` }))
        });
      })
      .catch((err) => reject(err));
})